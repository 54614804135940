import { DateTime } from 'luxon'

export class Timer {
  constructor () {
    this.currentDate = ''
    this.duration = ''
    this.diffDate = ''
    this.date = ''
    this.currentDate = DateTime.now().setZone('Asia/Yekaterinburg')
  }

  transformDate (date) {
    const hours = Math.floor(date / 1000 / 60 / 60)
    const minutes = Math.floor((date / 1000 / 60) - (hours * 60))
    const seconds = Math.floor((date / 1000) - (minutes * 60) - (hours * 60 * 60))
    const dNonFormat = [hours, minutes, seconds]
    this.formatDate(dNonFormat)
  }

  createDurationDate() {
    const split = this.duration.split(' ')
    const splitDate = split[0].split('-')
    const splitTime = split[1].split(':')
    return splitDate.concat(splitTime).map(item => +item)
  }

  formatDate (date) {
    const arr = []
    date.forEach(time => {
      if (String(time).length < 1) {
        time = '00'
        arr.push(time)
        return
      }

      if (String(time).length < 2) {
        time = '0' + time
        arr.push(time)
        return
      }

      arr.push(time)
    })

    this.date = arr.join(':')
  }

  differentDate () {
    const date2 = DateTime.utc(...this.createDurationDate())
    const diff = date2.diff(this.currentDate)
    this.diffDate = diff.toObject().milliseconds - 1000 * 60 * 60 * 5
  }

  decrementSeconds () {
    const decrementDate = +this.diffDate - 1000
    this.diffDate = decrementDate
    this.transformDate(decrementDate)
  }
}
