import { computed, watch } from 'vue'
import store from '@/store'

// Для ререндера по подписке центрифуги
export default function (key, renderCallBack) {
  const reRender = computed(() => store.getters[key])

  watch(reRender, bool => {
    if (!bool) return
    renderCallBack()
  })
}
