const POSITION_CRITERION_TABLE_HEAD_MODAL = [
  {
    name: 'Наименование параметра',
    value: 'full_name'
  },
  {
    name: 'Группа критерия',
    value: 'group_name'
  },
  {
    name: 'В/К параметра',
    value: 'coef'
  },
  {
    name: 'Значение',
    value: 'value'
  },
  {
    name: 'Баллы',
    value: 'balls',
  }
]

const LOT_POSITION_TABLE_HEAD = [
  {
    name: '№:',
    value: 'sequence'
  },
  {
    name: 'Наименование позиции',
    value: 'name'
  },
  {
    name: 'Характеристика',
    value: 'characteristic'
  },
  {
    name: 'Кол-во ТМЦ',
    value: 'quantity'
  },
  {
    name: 'Цена за ед. ТМЦ',
    value: 'price',
  },
  {
    name: 'НДС',
    value: 'VAT',
  },
  {
    name: 'Сумма с НДС',
    value: 'totalPrice',
  }
]

const PERSONAL_AREA_CHANGELOG_TH = [
  {
    name: '№',
    value: 'id',
    filter: false
  },
  {
    name: 'Дата и время',
    value: 'action_at',
    filter: false
  },
  {
    name: 'Событие',
    value: 'actions',
    filter: false,
    decorator: {
      name: 'color'
    }
  },
  {
    name: 'Ответственный',
    value: 'ownerName'
  },
  {
    name: 'Причина',
    value: 'cause'
  }
]

export {
  LOT_POSITION_TABLE_HEAD,
  POSITION_CRITERION_TABLE_HEAD_MODAL,
  PERSONAL_AREA_CHANGELOG_TH
}
