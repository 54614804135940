<template>
  <div class="status">
    <div class="status__inner">
        <div class="status__description">
          <h2 class="status__counter">№ {{ counter }}</h2>
          <h3 class="status__title mb-20">{{ title }}</h3>

          <StatusInfo
            :type="type"
            :info="info" />
        </div>

      <div class="status__main">
        <div class="status__subtitle">До окончания торгов:</div>
        <VTimer
          :duration="getDuration"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StatusInfo from '@/components/statusElements/VStatusInfo'
import VTimer from '@/components/VTimer'
import { computed } from 'vue'
export default {
  props: {
    counter: {
      type: [String, Number],
      required: true
    },
    title: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'trade'
    },
    info: {
      type: Array,
      required: true
    },
    duration: {
      type: String,
      required: false
    }
  },
  setup (props) {
    const getDuration = computed(() => {
      return props.duration
    })

    return {
      getDuration
    }
  },
  components: {
    VTimer,
    StatusInfo
  }
}
</script>
