<template>
  <div class="block__subtitle mt-60 mb-40">Состояние торгов</div>

  <SpinnerPage v-if="isLoading" />

  <VStatusTrade
    v-if="!isLoading"
    :counter="getLot?.id || 1"
    :title="getLot?.name || 'Имя лота'"
    :duration="getLot?.duration"
    type="bidding"
    :info="info"
  />

  <VStatusLot
    v-if="!isLotDownDivisibility && storyCount && !isLoading"
    :lot="getLot"
    :storyCount="storyCount"
    :lastOptions="lastOptions"
    class="mb-40"
  />

  <VStatusNewPriceOffer
    v-if="!isLotDownDivisibility && !isLoading"
    :class="[!storyCount ? 'mt-60' : '']"
    :lot="getLot"
    :lastOptions="lastOptions"
  />

  <div v-if="isLotDownDivisibility && !isLoading" class="flex gap-15" style="max-height: 700px">
    <VStatusLotDownBestOffer
      class="bg--yellow mt-20"
      :lot="getLot"
      :offer-count="storyCount"
    />

    <VTable
      :head-row="lotPositionsTableHead"
      :rows="lotPositionsTableRow"
      :is-pagination="false"
      :is-min-height="false"
      :is-checkbox="false"
      not-api
      @sort="sortLotPositionsHandled"
    />
  </div>

  <div v-if="storyCount && !isLoading">
    <div class="block__subtitle mt-60 mb-40">Предложения</div>

    <VTabs
      :is-small="true"
      :items="biddingStatus"
      class="mb-30"
    />

    <VTable
      :max-width="false"
      v-if="isHistoryRoute"
      :head-row="headRow"
      :is-checkbox="false"
      :rows="rows"
    />

    <BarChart
      v-else
      ref="barChart"
      :chart-options="options"
      :chart-data="barData"
      :width="1350"
      :height="670"
      css-classes="chart-container"
    />
  </div>
</template>

<script>
import VStatusTrade from '@/components/statusElements/VStatusTrade'
import VStatusLot from '@/components/statusElements/VStatusLot'
import VStatusNewPriceOffer from '@/components/statusElements/VStatusNewPriceOffer'
import VStatusLotDownBestOffer from '@/components/statusElements/VStatusLotDownBestOffer'
import VTabs from '@/components/views/Tabs/VTabs'
import { biddingStatus } from '@/utils/tabs'
import VTable from '@/components/ui/table/VTable'
import { BarChart } from 'vue-chart-3'
import { useRoute, useRouter } from 'vue-router'
import { computed, inject, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { getLatestPrice } from '@/http/offer/offerApi'
import { checkCurrentStatus } from '@/utils/lot/function.lot'
import { useChart } from '@/use/Base/useChart'
import { BIDDING_CONTROLLER, LOT_CONTROLLER } from '@/utils/consts'
import { ConsoleLogger } from '@/extension/Error/ConsoleLogger'
import { formatCurrency } from '@/utils/format/number'
import useReRender from '@/use/Base/useReRender'
import SpinnerPage from '@/components/views/Spinner/SpinnerPage'
import useLoading from '@/use/Base/useLoading'
import { refreshArr } from '@/utils/reactive'
import { formatDateTime } from '@/utils/format/date'
import { LOT_POSITION_TABLE_HEAD } from '@/utils/consts/tableHead'
import { sortBy } from '@/utils/array'
import { findOfferBy } from '@/utils/model/Lot/find'

export default {
  props: {
    title: {
      type: String,
      required: false
    }
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const lotController = inject(LOT_CONTROLLER)
    const storyCount = ref('')
    const lastOptions = reactive({
      last: []
    })
    const info = reactive([])
    const biddingProcedureController = inject(BIDDING_CONTROLLER)
    const { isLoading, startLoading, finishLoading } = useLoading()
    const ws = inject('ws');

    // eslint-disable-next-line no-unexpected-multiline
    (function subChannel() {
      try {
        ws.subscribe(`Lot_${route.params.id}`, 'subscribeLot')
      } catch (e) {
        ConsoleLogger.defaultLogWarn({ groupName: 'WebSocket', path: 'BiddingStatusView', log: e })
      }
    })()

    const headRow = [
      {
        name: 'Место',
        value: 'counter'
      },
      {
        name: 'Ставка',
        value: 'member'
      },
      {
        name: 'Ценовое предложение',
        value: 'price'
      },
      {
        name: 'Время подачи ЦП',
        value: 'updated_at'
      }
    ]

    const rows = reactive([])

    const isHistoryRoute = computed(() => {
      return route.fullPath.includes('history')
    })

    const getLot = computed(() => {
      return store.getters['lot/getLot']
    })

    const getInfo = async () => {
      store.commit('lot/setLot', await lotController.getLotById(route.params.id))
      if (!checkCurrentStatus([6, 7])) {
        return router.push({ name: 'bidder' })
      }
      refreshArr(info, [
        { title: 'ФИО ответственного за лот', description: getLot.value.user.organization_name || getLot.value.user.name },
        { title: 'Номер закупки', description: 'уточнить' },
        { title: 'Статус', description: getLot.value.status.name }
      ])
    }

    const { setBarDataOffer, barData, barChart, dataChart, options } = useChart()

    const getLastPrice = async () => {
      const id = route.params.id
      const { data } = await getLatestPrice(id)
      const offer = await biddingProcedureController.getBidderCardList(id)
      storyCount.value = offer.length
      lastOptions.last = data.last_offers
      setBarDataOffer(offer)
      setRows(offer)
    }

    const setRows = (data) => {
      const l = data.length
      const newRows = data.map((item, i) => ({
        counter: l - i,
        member: 'Ставка ' + (i + 1),
        price: formatCurrency(item.price),
        updated_at: formatDateTime(item.updated_at)
      }))

      refreshArr(rows, newRows)
    }

    onMounted(renderPage)

    async function renderPage () {
      try {
        startLoading()
        await Promise.all([
          getInfo(),
          getLastPrice()
        ])
        if (isLotDownDivisibility.value) {
          setLotPositionsTableRow()
        }
      } finally {
        finishLoading()
      }
    }

    useReRender('lot/getReRenderLot', renderPage)

    // Лот с понижением + делимый
    const isLotDownDivisibility = computed(() => {
      return getLot.value.procedure_type_id === 2 && !getLot.value.divisibility
    })
    const lotPositionsTableHead = LOT_POSITION_TABLE_HEAD.map(item => ({
      ...item,
      filter: true
    }))
    const lotPositionsTableRow = reactive([])
    function setLotPositionsTableRow () {
      // НДС
      const VAT = getLot.value.nds_counter

      const tableRow = getLot.value.position.map((item, i) => {
        const bestOffer = findOfferBy(item.id, lastOptions.last)

        let price
        let quantity
        let hasPrice = true

        if (bestOffer) {
          price = bestOffer.price
          quantity = bestOffer.quantity
        } else {
          quantity = +item.quantity
          if (item.price) {
            price = +item.price
          } else {
            hasPrice = false
          }
        }

        return {
          ...item,
          sequence: i + 1,
          VAT,
          price: hasPrice ? formatCurrency(price) : 'Нет ставок',
          quantity,
          totalPrice: hasPrice ? formatCurrency(price * quantity) : '-'
        }
      })

      refreshArr(lotPositionsTableRow, tableRow)
    }
    function sortLotPositionsHandled (sort) {
      sortBy(sort, lotPositionsTableRow)
    }

    return {
      isLoading,
      options,
      isHistoryRoute,
      getLot,
      info,
      headRow,
      biddingStatus,
      dataChart,
      barChart,
      barData,
      storyCount,
      lastOptions,
      rows,
      isLotDownDivisibility,
      lotPositionsTableHead,
      lotPositionsTableRow,
      sortLotPositionsHandled
    }
  },
  components: {
    VStatusLotDownBestOffer,
    SpinnerPage,
    VTable,
    VTabs,
    VStatusNewPriceOffer,
    VStatusLot,
    VStatusTrade,
    BarChart
  }
}
</script>
