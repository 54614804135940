import {
  Chart,
  Tooltip,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement
} from 'chart.js'
import { computed, reactive, ref } from 'vue'
import { refreshArr } from '@/utils/reactive'
import { formatDateTime } from '@/utils/format/date'

export function useChart () {
  const BACKGROUND_COLOR_DATA = '#FFED00'
  const BAR_THICKNESS = 35

  const barChart = ref(null)
  const dataChart = reactive([])
  const labelsDate = reactive([])

  const options = ref({
    responsive: true,
    label: 'My first dataset'
  })

  Chart.register(BarController, Tooltip, CategoryScale, LinearScale, BarElement)

  const barData = computed(() => {
    return {
      labels: labelsDate,
      datasets: [
        {
          label: 'Цена',
          data: dataChart,
          backgroundColor: BACKGROUND_COLOR_DATA,
          barThickness: BAR_THICKNESS,
          showLine: false
        }
      ]
    }
  })

  function setBarDataOffer (offers) {
    const chart = []
    const labels = []

    offers.forEach(offer => {
      chart.push(offer.price)
      labels.push(formatDateTime(offer.updated_at))
    })

    refreshArr(dataChart, chart)
    refreshArr(labelsDate, labels)
  }

  return {
    barData,
    barChart,
    options,
    dataChart,
    setBarDataOffer
  }
}
