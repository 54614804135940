// поиск оффера в лоте
import { findBy } from '@/utils/array'

function findOfferBy (positionId, offers) {
  const offer = findBy('position_id', positionId, offers)
  if (!offer) return null

  return {
    id: offer.id,
    price: +offer.price,
    quantity: +offer.answers.position.quantity
  }
}

export {
  findOfferBy
}
