import { ref } from 'vue'
import { formatPrice } from '@/utils/utils'

export function usePrice (props) {
  const currentPrice = ref('')

  const getCurrentPrice = () => {
    const lastList = props.lastOptions.last

    if (!lastList?.length) {
      currentPrice.value = props.lot.start_price
      return
    }

    let sum = 0
    lastList.forEach((pos) => {
      sum += +pos.price
    })

    currentPrice.value = sum
    return sum
  }

  const getPriceStepTo = () => {
    if (props.lot.is_procent) {
      return `${props.lot.max_step} %`
    }

    const step = currentPrice.value * props.lot.max_step / 100
    return formatPrice(step)
  }

  const getPriceStepFrom = () => {
    if (props.lot.is_procent) {
      return `${props.lot.min_step} %`
    }

    const step = currentPrice.value * props.lot.min_step / 100
    return formatPrice(step)
  }

  const getPrice = (type) => {
    getCurrentPrice()
    if (props.lot.is_procent) {
      return currentPrice.value * props.lot[`${type}_step`] / 100
    } else {
      return currentPrice.value - props.lot[`${type}_step`]
    }
  }

  const getCurrentStepDecline = () => {
    if (!Number(props.lot.start_price)) return '0'
    if (props.lot.start_price === currentPrice.value) {
      return '0'
    }
    return String(Math.floor((props.lot.start_price - currentPrice.value) / props.lot.start_price * 100)) + '%'
  }

  return {
    getPrice,
    getPriceStepTo,
    getPriceStepFrom,
    getCurrentStepDecline,
    getCurrentPrice,
    currentPrice
  }
}
