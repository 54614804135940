<template>
<!-- v-if="!getIsBest"  -->
  <div class="new-price-offer">
    <h3 class="status__title mb-20">Новое ценовое предложение</h3>
    <div class="new-price-offer__info">
      <div>
        <div class="text-block__subtitle">Разрешается подать ЦП с шагом аукциона</div>
      </div>
      <div class="new-price-offer__buttons">
        <button class="btn btn-primary btn-bg-green" type="button" @click="goLotOffer">Участвовать</button>
      </div>
    </div>
  </div>

<!--  <div class="new-price-offer best" v-else>-->
<!--    <h2 class="status__counter text-center">Ваше ценовое предложение лучшее</h2>-->
<!--  </div>-->
</template>

<script>
import { computed } from 'vue'
import { usePrice } from '@/use/Base/usePrice'
import { useStore } from 'vuex'
import { goLotOffer } from '@/utils/goRoute'

export default {
  props: {
    lastOptions: {
      type: Object,
      required: true
    },
    lot: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const store = useStore()

    const getIsBest = computed(() => {
      return props.lastOptions.last?.find((pos) => pos.user_id === store.getters['auth/getUser'].id && !pos.is_fake)
    })

    return {
      getIsBest,
      goLotOffer,
      ...usePrice(props)
    }
  }
}
</script>
