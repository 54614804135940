<template>
  <div class="timer" v-if="getFormatingTimer">
    <div :class="['timer__element', time === ':' ? 'colon' : '']" v-for="(time, i) in getFormatingTimer" :key="i">
      {{ time }}
    </div>
  </div>

  <div class="scale-50" v-else>
    <VSpinnerLoader />
  </div>
</template>

<script>

import { computed, onDeactivated, onMounted, ref, watch } from 'vue'
import { Timer } from '@/extension/Timer/Timer'
import VSpinnerLoader from '@/components/views/Spinner/VSpinnerLoader'

export default {
  components: { VSpinnerLoader },
  props: {
    duration: {
      type: String,
      required: false
    }
  },
  setup (props) {
    const timer = ref('')
    const isDays = ref(false)
    const isStop = ref(false)
    const instanceTime = new Timer()
    const DELAY_SECONDS = 1000
    const DELAY_MINUTES = 10000

    const getFinishDate = computed(() => {
      return props.duration
    })

    const getFormatingTimer = computed(() => {
      return formatTimer(timer.value)
    })

    onMounted(async () => {
      initTimer()
    })

    onDeactivated(() => {
      isStop.value = true
    })

    function startTimer () {
      setTimeout(() => {
        if (instanceTime.diffDate <= 0 || isStop.value) {
          return
        }
        instanceTime.decrementSeconds()
        timer.value = instanceTime.date
        return startTimer()
      }, isDays.value ? DELAY_MINUTES : DELAY_SECONDS)
    }

    function formatTimer (timer) {
      return timer
    }

    function initTimer () {
      if (props.duration) {
        instanceTime.duration = props.duration
        instanceTime.differentDate()
        startTimer()
      }
    }

    watch(getFinishDate, async () => {
      initTimer()
    })

    return {
      getFormatingTimer,
      formatTimer,
      getFinishDate,
      timer
    }
  }
}
</script>

<style scoped>
.scale-50 {
  transform: scale(.7);
}
</style>
