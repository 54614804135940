<template>
  <div class="offer-best-down column gap-30 p-30">
    <h3 class="status__title">Лучшее предложение</h3>

    <VTextBlock
      subtitle="Продолжительность"
      :description="getDuration"
    />
    <VTextBlock
      subtitle="Подано предложений"
      :description="offerCount"
    />
    <VTextBlock
      subtitle="Кол-во участников"
      :description="lot.bidder_count"
    />
    <VTextBlock
      subtitle="Кол-во позиций"
      :description="lot.position.length"
    />
    <VTextBlock
      subtitle="Дата и время окончания"
      :description="formatDateTime(lot.duration)"
    />

    <button
      class="btn btn-primary btn-bg-green btn--p mt-auto"
      style="width: initial"
      type="button"
      @click="goLotOffer"
    >
      Подать предложение
    </button>
  </div>
</template>

<script setup>
import VTextBlock from '@/components/textblock/VTextBlock'

import { defineProps } from 'vue'
import { useDuration } from '@/use/useDuration'
import { goLotOffer } from '@/utils/goRoute'
import { formatDateTime } from '@/utils/format/date'

const props = defineProps({
  lot: {
    type: Object,
    required: true
  },
  offerCount: {
    type: [String, Number],
    required: true
  }
})

const { getDuration } = useDuration(props.lot.started_at, props.lot.duration)
</script>
