<template>
  <div class="offer mt-40">
    <div class="offer__inner">
      <div class="offer__squad mr-20">
        <div class="offer__element">
          <div class="text-block">
            <h4 class="text-block__subtitle">Начальная цена:</h4>
            <h4 class="status__title">{{ formatPrice(lot.start_price) }}</h4>
          </div>
        </div>

        <div class="offer__element offer__element-bg-yellow">
          <div class="text-block">
            <h4 class="text-block__subtitle">Текущая цена:</h4>
            <h4 class="status__title">{{ formatPrice(getCurrentPrice()) || formatPrice(lot.start_price) }}</h4>
          </div>
        </div>

        <div class="offer__element">
          <div class="text-block">
            <h4 class="text-block__subtitle">
              Шаг торгов:
            </h4>
            <p class="text-block__description">
              от {{ getPriceStepFrom() }}
            </p>
            <p class="text-block__description">
              до {{ getPriceStepTo() }}
            </p>
          </div>
        </div>

        <div class="offer__element">
          <VTextBlock
            subtitle="Текущее снижение"
            :description="getCurrentStepDecline()"
          />
        </div>
      </div>

      <div class="offer__price">
        <div class="offer__left">
          <h3 class="status__title mb-30">Лучшее предложение</h3>
          <VTextBlock subtitle="Шаг снижения начальной цены" class="mb-30" :description="getCurrentStepDecline()"/>
          <h2 class="status__counter">{{ formatPrice(getCurrentPrice()) }}</h2>
        </div>
        <div class="offer__right">
          <VTextBlock subtitle="Продолжительность"  class="mb-30" :description="getDurability"/>
          <VTextBlock subtitle="Подано предложений" class="mb-30" :description="storyCount"/>
          <VTextBlock subtitle="Дата и время окончания" :description="lot.duration"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VTextBlock from '@/components/textblock/VTextBlock'
import { usePrice } from '@/use/Base/usePrice'
import { computed } from 'vue'
import { calculateRangeBetweenDate, formatPrice } from '@/utils/utils'

export default {
  props: {
    lot: {
      type: Object,
      required: true
    },
    storyCount: {
      type: [String, Number],
      required: true
    },
    lastOptions: {
      type: Object,
      required: true
    }
  },
  components: { VTextBlock },
  setup (props) {
    const getDurability = computed(() => {
      return calculateRangeBetweenDate(props.lot.started_at, props.lot.duration)
    })
    return {
      getDurability,
      formatPrice,
      ...usePrice(props)
    }
  }
}
</script>
